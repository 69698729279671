<template>
  <div class="openApp2">
    <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn1" :appid="appId" :extinfo="extinfo"
      class="open-launch-app" @error="openError">
      <script type="text/wxtag-template">
        <style>.btn{
                width: 100%;
                height: 35px;
              }</style>
          <div class="btn"></div>
      </script>
    </wx-open-launch-app>
    <div class="linkApp" @click="linkApp">打开APP</div>
  </div>
</template>

<script>
import linkApp from "@/utils/linkApp";
import { isWeixin } from "@/utils/common";
import { wxShare, init, is_version } from "@/utils/wxShare";
export default {
  name: "openApp",
  props: {
    path: {
      type: String,
      default: "",
    },
    id: {
      type: [String, Number],
      default: "",
    },
    channelId: {
      type: [String, Number],
      default: "",
    },
    date: {
      type: [String, Number],
      default: "",
    },
    programId: {
      type: [String, Number],
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      appId: "wx720bb4d01c924127",
      isWeixin: isWeixin(),
      is_version: is_version(),
    };
  },
  computed: {
    extinfo() {
      return JSON.stringify({
        path: "/" + this.path,
        url: this.url,
        id: this.id,
        channelId: this.channelId,
        date: this.date,
        programId: this.programId,
      });
    },
  },
  created() {},
  methods: {
    linkApp() {
      const data = {
        path: this.path,
         url: this.url,
        param: this.id,
        channelId: this.channelId,
        date: this.date,
        programId: this.programId,
      };
      linkApp(data);
    },
    openError() {
      this.$router.push({ name: "index" });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.openApp2 {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #f04040;
  border: 0px solid #f04040;
  // box-shadow: 0 2px 5px #f04040;
  opacity: 1;
  border-radius: 0rem;
  font-size: 14px;
  font-weight: 500;
  width: 100px;
  text-align: center;
  line-height: 35px;
  height: 35px;
  border-radius: 20px;
  color: #ffffff;
}
</style>
