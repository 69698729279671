<template>
  <div class="kds" v-if="canShow">
    <div class="header">
      <img v-if="channel_bg_image" :src="channel_bg_image" class="header" />
    </div>

    <div class="channels">
      <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
        <script type="text/wxtag-template">
          <style>.btn {
              width:100%;
              height: 80
            }</style>
            <div class="btn"></div>
        </script>
      </wx-open-launch-app>
      <div class="channel" v-for="(item, index) in channels" :key="index" @click="linkApp">
        <img :src="channelId == item.id ? item.channel_logo : item.channel_logo_gray" class="logo" />
        <div class="text" :class="index == 0 ? 'active' : ''">{{ item.channel_name }}</div>
      </div>
    </div>
    <div class="content">
      <!-- <wx-open-launch-app v-if="isWeixin && is_version" id="launch-btn" :appid="appId" :extinfo="extinfo" class="open-launch-app" @error="openError">
        <script type="text/wxtag-template">
          <style>.btn {
              width:100%;
              height: 100%
            }</style>
            <div class="btn"></div>
        </script>
      </wx-open-launch-app>
      <div v-for="(item, index) in programList" :key="index">
        <div class="list" @click="linkApp">
          <div class="time">{{ item.program_time }}</div>
          <div class="name">{{ item.program_name }}</div>
        </div>
      </div> -->
      <div class="center">
        <div v-if="channel_str">你现在正在打开的是{{ channel_str }}</div>
        <div v-if="program_str" class="big">{{ program_str }}</div>
        <div>打开快艺学app收听</div>
      </div>
    </div>
    <OpenApp2 :path="path" :id="id" :channelId="channelId" :date="date" :programId="programId" />
  </div>
</template>

<script>
  import OpenApp2 from "@/components/openApp2";
  import linkApp from "@/utils/linkApp";
  import { getTvChannel, getWeekTime, getProgram } from "@/utils/api.js";
  import { isWeixin } from "@/utils/common";
  import { wxShare, init, is_version } from "@/utils/wxShare";
  export default {
    name: "kds",
    data() {
      return {
        canShow: false,
        path: "watchTv",
        id: "",
        channel_bg_image: "",
        channels: [],
        share_config: {},
        appId: "wx720bb4d01c924127",
        isWeixin: isWeixin(),
        is_version: is_version(),
        channelId: "",
        date: "",
        programId: "",
        query: "",
        weekTime: "",
        programList: [],
        channel_str: "",
        program_str: "",
      };
    },
    components: { OpenApp2 },
    computed: {
      extinfo() {
        return JSON.stringify({
          path: this.path,
          id: this.id,
          channelId: this.channelId,
          date: this.date,
          programId: this.programId,
        });
      },
    },
    methods: {
      openError() {
        this.$router.push({ name: "index" });
      },
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
          channelId: this.channelId,
          date: this.date,
          programId: this.programId,
        };
        linkApp(data);
      },
      async getTvChannel() {
        try {
          this.channelId = this.getQueryString("channelId");
          this.date = this.getQueryString("date");
          this.programId = this.getQueryString("programId");
          this.query = `?channelId=${this.channelId}&date=${this.date}&programId=${this.programId}`;
        } catch (err) {}
        const res = await getTvChannel();
        this.channels = res.data.data;

        this.channels.forEach((val, index) => {
          if (val.id == this.channelId) {
            this.channel_bg_image = res.data.data[index].channel_bg_image;
            this.share_config = res.data.data[index].share_config;
            this.channel_str = res.data.data[index].channel_name;
          }
        });

        const res3 = await getProgram(this.channelId, this.date);
        this.programList = res3.data.data;
        this.programList.forEach((val, index) => {
          if (val.id == this.programId) {
            this.program_str = val.program_name;
          }
        });
        this.canShow = true;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link + this.query, this.share_config.summary);
      },

      getQueryString(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) {
          return unescape(r[2]);
        }
        return null;
      },
    },
    created() {
      this.getTvChannel();
    },
  };
</script>

<style lang="scss" scoped>
  .kds {
    .header {
      width: 100%;
      height: 200px;
      background: url("~@/assets/images/bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
    }
    .taibiao {
      display: block;
      width: 50px;
      margin: 0 auto;
    }
    .channels {
      position: relative;
      display: flex;
      padding: 0 15px;
      height: 80px;
      align-items: center;

      .channel {
        width: 25%;
        text-align: center;
        img {
          width: 30px;
          height: 30px;
          display: block;
          margin: 0 auto;
        }
        .text {
          font-size: 12px;
          margin-top: 5px;
        }
        .active {
          color: gray;
        }
      }
    }
    .center {
      text-align: center;
      font-size: 14px;
      color: #333;
      margin-top: 10px;
      line-height: 1.8;
      .big {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .content {
      height: calc(100vh - 280px);
      overflow-y: scroll;
      font-size: 16px;
      border-top: 5px solid #ccc;
      padding-bottom: 100px;
      position: relative;
      .list {
        display: flex;
        padding: 3% 5%;
        border-bottom: 1px dashed #eee;
        .time {
          font-size: 14px;
        }
        .name {
          margin-left: 10%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
</style>
